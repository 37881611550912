var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('tournament.tasks_title'))+" ")]},proxy:true},{key:"button",fn:function(){return [_c('div',{staticClass:"mr-1",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('tournament.filtered'))+" ")])],1)]},proxy:true}])}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(task)",fn:function(ref){
var item = ref.item;
return [(item.task)?_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(_vm.checkLocales(item.task.name))+" ")]):_vm._e()]}},{key:"cell(tournament)",fn:function(ref){
var item = ref.item;
return [(item.tournament)?_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(_vm.checkLocales(item.tournament.name))+" ")]):_vm._e()]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":false,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.tournament_tasks') : _vm.$t('add.tournament_tasks'),"cancel-variant":"danger","centered":"","size":"lg"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(!_vm.updatingItem)?_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(_vm.createOneMore),callback:function ($$v) {_vm.createOneMore=$$v},expression:"createOneMore"}},[_vm._v(" "+_vm._s(_vm.$t('titles.add_more'))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"6"}},[_c('tournaments-select',{model:{value:(_vm.dataForModal.tournament),callback:function ($$v) {_vm.$set(_vm.dataForModal, "tournament", $$v)},expression:"dataForModal.tournament"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('tournament.simple_tasks_title'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('tasks-select',{model:{value:(_vm.dataForModal.task),callback:function ($$v) {_vm.$set(_vm.dataForModal, "task", $$v)},expression:"dataForModal.task"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.start_date')))]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.start_date'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"mb-1 form-control",attrs:{"config":{
                                    minDate: _vm.calendarMinDate,
                                    maxDate: _vm.calendarMaxDate,
                                    allowInput: true
                                },"placeholder":("" + (_vm.$t('choose.date'))),"name":"date"},model:{value:(_vm.dataForModal.start_date),callback:function ($$v) {_vm.$set(_vm.dataForModal, "start_date", $$v)},expression:"dataForModal.start_date"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.qty')))]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.qty'))),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":("" + (_vm.$t('enter.qty'))),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.qty),callback:function ($$v) {_vm.$set(_vm.dataForModal, "qty", $$v)},expression:"dataForModal.qty"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }